/* 
Author: Sreenivassan R
Module:  Promotions & Offers Management
*/
import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import classes from "../ModalDetails.module.css";
import { toast } from "react-toastify";
import Toast from "../Toast";
import { useLocation, useNavigate } from "react-router-dom";
import PromotionsCreateModal from "./PromotionsCreateModal";
import { fetchPromotionData, promoDelete } from "../../util/promotions";
import DataGridDefault from "../UI/DataGridDefault";
import { Tooltip } from "@mui/material";

function PromotionsManagement() {
  const [promotionDataGet, setPromotionDataGet] = useState([]);
  const [promotionDataGetEdit, setPromotionDataGetEdit] = useState([]);
  const [show, setShow] = useState(false);
  const [id, setId] = useState();
  const [loading, setLoading] = useState();

  const authToken = localStorage.getItem("token");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      setLoading(true);
      fetchPromotionData(setPromotionDataGet, authToken);
    }
    setLoading(false);
  }, []);

  let columns = [
    { field: "code", headerName: "Offer Code", flex:1 },
    { field: "description", headerName: "Offer Name", flex:1},
    {
      field: "termsandconditions",
      headerName: "Description",
      flex:1,
    },
    { field: "discount", headerName: "Value", flex:1 },
    { field: "targetuser", headerName: "Target Audience", flex:1 },

    { field: "endOffer", headerName: "End Date & Time",flex:1},
    {
      field: "status",
      headerName: "Status",
      flex:1,
      renderCell: (params) => {
        let status;
        let today = new Date();
        let offerEnd = params.row.end;
        if (today > offerEnd) {
          status = false;
        } else {
          status = true;
        }
        let backgroundColor = "";
        let color = "";
        if (status === true) {
          backgroundColor = "#ade2ad";
          color = "#34A853";
        } else if (status === false) {
          backgroundColor = "#f4de9e";
          color = "#FFC107";
        }
        return (
          <div
            style={{
              backgroundColor,
              color,
              width: "93px",
              height: "27px",
              textAlign: "center",
              padding: "2px",
              fontFamily: "Poppins, sans-serif",
              fontSize: "12px",
              lineHeight: "23px",
              borderRadius: "4px",
            }}
          >
            {status ? "Enable" : "Disable"}
          </div>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "",
      width: 100,
      cellClassName: "actions",
      getActions: (val) => {
        var id = val.id;
        let today = new Date();
        if (today > val.row.start) {
          return [
            <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
              label="Edit"
              className={classes.textPrimary}
              color="inherit"
            />,
            <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
              label="Delete"
              className={classes.textPrimary}
              color="inherit"
            />,
          ];
        }
        if (today > val.row.start) {
          return [
            <GridActionsCellItem
            icon={
              <Tooltip title="Edit">
                <EditIcon />
              </Tooltip>
            }
              label="Edit"
              className={classes.textPrimary}
              color="inherit"
            />,
            <GridActionsCellItem
            icon={
              <Tooltip title="Delete">
                <DeleteIcon />
              </Tooltip>
            }
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
          icon={
            <Tooltip title="Edit">
              <EditIcon />
            </Tooltip>
          }
            label="Edit"
            onClick={() => handleEditClick(val.row)}
            color="inherit"
          />,
          <GridActionsCellItem
          icon={
            <Tooltip title="Delete">
              <DeleteIcon />
            </Tooltip>
          }
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  const handleEditClick = (row) => {
    console.log(row);
    setId(row.id);
    setPromotionDataGetEdit(row);
    setShow(true);
  };

  function handleDeleteClick(id) {
    promoDelete(id, authToken, notifysuccess);
  }

  function promotionAddHandler() {
    setId("");
    setPromotionDataGetEdit("");
    setShow(true);
  }
  function hideHandler() {
    setShow(false);
  }

  return (
    <div className={classes.GridWrapper}>
   
      <DataGridDefault
       heading="Promotions & Offer"
       rows={promotionDataGet}
       columns={columns}
       loading={loading}
       permission={permission}
       date={false}
       createFunction={promotionAddHandler}
       sortField={"endOffer"}
       lists={promotionDataGet}
       report="promotions"
      />
      {show && (
        <PromotionsCreateModal
        updatePromotionDataGet={promotionDataGetEdit}
          id={id}
          notifysuccess={notifysuccess}
          Hide={hideHandler}
        />
      )}

      <Toast />
    </div>
  );
}

export default PromotionsManagement;
