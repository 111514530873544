/* 
Author: Sreenivassan R
Module: Price List
*/
import React, { useEffect, useState } from "react";
import { GridActionsCellItem } from "@mui/x-data-grid";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import classes from "../ModalDetails.module.css";
import Toast from "../Toast";
import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import DataGridDefault from "../UI/DataGridDefault";
import CreatePrice from "./CreatePrice";
import { fetchPriceData, priceDelete } from "../../util/price";
import { Tooltip } from "@mui/material";

function PriceList() {
  const [catagoryData, setCatagoryData] = useState([]);
  const [editCatgoryData, setEditCategoryData] = useState([]);
  const [show, setShow] = useState(false);
  const [render, setRender] = useState(false);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const routerstate = useLocation();
  let permission = routerstate.state;
  const navigate = useNavigate();

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  const notifyfail = () =>
    toast.error("Cannot Submit", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  /* Handler Function On Create Catagory Button */
  function inputCatagory() {
    setShow(true);
    setEditCategoryData("");
    setId(undefined);
    setEditCategoryData({ status: true });
  }

  function hideHandler() {
    setShow(false);
  }

  let columns = [
    { field: "id", headerName: "S No", flex: 1 },
    { field: "cost", headerName: "Cost", flex: 1 },
    { field: "color", headerName: "Colour", flex: 1 },
    {
      field: "imgurl",
      headerName: "Image",
      flex: 1,
      renderCell: (params) => {
        var url = params.row.imgurl;
        return (
          <div
            style={{
              width: "57px",
              height: "57px",
              textAlign: "center",
              padding: "2px",
              margin: "auto 0",
              borderRadius: "4px",
            }}
          >
            <img
              src={url}
              style={{ objectFit: "contain", width: "90%", height: "90%" }}
              alt="Invalid Image"
            />
          </div>
        );
      },
    },
    {
      field: "cardimgurl",
      headerName: "Lottery Card Image",
      flex: 1,
      renderCell: (params) => {
        var url = params.row.cardimgurl;
        return (
          <div
            style={{
              width: "57px",
              height: "57px",
              textAlign: "center",
              padding: "2px",
              margin: "auto 0",
              borderRadius: "4px",
            }}
          >
            {url ? (
              <img
                src={url}
                style={{ objectFit: "contain", width: "90%", height: "90%" }}
                alt="Invalid Image"
              />
            ) : (
              <div
                style={{
                  display: "grid",
                  placeItems: "center",
                  height: "100%",
                }}
              >
                <span style={{ fontSize: "10px", lineHeight: "10px" }}>
                  No Image Found.
                </span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        const status = params.row.status;
        let backgroundColor = "";
        let color = "";
        if (status === true) {
          backgroundColor = "#ade2ad";
          color = "#34A853";
        } else if (status === false) {
          backgroundColor = "#f4de9e";
          color = "#FFC107";
        }
        return (
          <div
            style={{
              backgroundColor,
              color,
              width: "93px",
              height: "27px",
              textAlign: "center",
              padding: "2px",
              fontFamily: "Poppins, sans-serif",
              fontSize: "12px",
              lineHeight: "23px",
              borderRadius: "4px",
            }}
          >
            {status ? "Enable" : "Disable"}
          </div>
        );
      },
    },
  ];

  /*  To Show the Button Icons depending upon the User Permison */
  if (permission && permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 100,
        cellClassName: "actions",
        getActions: (val) => {
          var id = val.row._id;
          var row = val.row;
          if (val.row.status === true)
            return [
              <GridActionsCellItem
                icon={
                  <Tooltip title="Edit">
                    <EditIcon />
                  </Tooltip>
                }
                label="Edit"
                className="textPrimary"
                onClick={() => handleEditClick(row)}
                color="inherit"
              />,
            ];
          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(row)}
              color="inherit"
            />,
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission && permission.edit && !permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (lottId) => {
          var id = lottId.row.id;

          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Edit">
                  <EditIcon />
                </Tooltip>
              }
              label="Edit"
              className="textPrimary"
              onClick={() => handleEditClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  } else if (permission && !permission.edit && permission.delete) {
    columns = [
      ...columns,
      {
        field: "actions",
        type: "actions",
        headerName: "",
        width: 150,
        cellClassName: "actions",
        getActions: (lottId) => {
          var id = lottId.row.id;

          return [
            <GridActionsCellItem
              icon={
                <Tooltip title="Delete">
                  <DeleteIcon />
                </Tooltip>
              }
              label="Delete"
              onClick={() => handleDeleteClick(id)}
              color="inherit"
            />,
          ];
        },
      },
    ];
  }

  const authToken = localStorage.getItem("token");

  useEffect(() => {
    if (!authToken) {
      navigate("/");
    } else {
      setLoading(true);
      fetchPriceData(setCatagoryData, authToken);
      setLoading(false);
    }
  }, [render, authToken]);

  /* Handler function on Edit Icon Click */
  const handleEditClick = (row) => {
    setId("");
    console.log(row);
    setId(row._id);
    setEditCategoryData(row);
    setShow(true);
  };

  /* Handler function on Delete Icon Click */
  function handleDeleteClick(row) {
    priceDelete(row, authToken, notifysuccess, notifyfail, setRender);
  }

  return (
    <div className={classes.GridWrapper}>
      <DataGridDefault
        heading="Price List"
        rows={catagoryData}
        columns={columns}
        loading={loading}
        permission={permission}
        date={false}
        createFunction={inputCatagory}
        lists={catagoryData}
        report="price"
      />
      <Toast />

      {show && (
        <CreatePrice
          id={id}
          updateCatagoryDataGet={editCatgoryData}
          Hide={hideHandler}
        />
      )}
    </div>
  );
}

export default PriceList;
