/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;
export const fetchCatagoryData = async (
  setCatagoryData,
  authToken,
  retry = true
) => {
  try {
    const response = await fetch(
      ADMINURL + "/admin/getlotterycategory?mode=all",
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
      }
    );
    const responseData = await response.json();

    if (responseData.sts === "2") {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchCatagoryData(setCatagoryData, authToken, false);
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    const catagoryData = responseData.data.map((item, index) => {
      return {
        _id: item._id,
        id: index + 1,
        categoryname: item.categoryname,
        subcategoryname: item.subcategoryname,
        imgurl: item.imgurl,
        status: item.status,
        cardimgurl: item.cardimgurl ? item.cardimgurl : "",
        radialcolor: item.radialcolor ? item.radialcolor : ["#000", "#000"],
        color: item.color,
      };
    });
    console.log("catagoryData", responseData);
    setCatagoryData(catagoryData);
    console.log("Data submitted successfully:", catagoryData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const catagoryDelete = async function handleDeleteClick(
  row,
  authToken,
  notifysuccess,
  notifyfail,
  setRender
) {
  console.log(row);
  const isConfirmed = window.confirm("Do You Want to Delete this Category?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/saveCategory", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: row, mode: "delete" }),
      });

      const data = await response.json();
      if (data.sts === "1") {
        notifyfail(data.msg);
      } else if (data.sts === "0") {
        notifysuccess("Success");
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      } else {
        notifyfail("Something Went Wrong");
      }
      setRender((prev) => !prev);
    } catch (error) {
      console.error("Error:", error);
    }
  }
};

const saveCatagory = async (payLoad) => {
  console.log({ payLoad });
  const authTokenOne = localStorage.getItem("token");
  try {
    var response = await fetch(ADMINURL + "/admin/saveCategory", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokenOne}`,
      },
      body: JSON.stringify(payLoad),
    });
    var responseData = await response.json();
    return responseData;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const catagoryCreate = async function catagoryHandler(
  id,
  authToken,
  setCatagoryData,
  setIsSubmitting,
  notifysuccess,
  Hide,
  toast,
  catagoryData,
  subcatagoy
) {
  setIsSubmitting(true);
  try {
    const { _id, ...catagoryDataedit } = catagoryData;
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };

    var responseData = await saveCatagory({ ...catagoryDataedit, ...postdata });

    // If token expired, regenerate token and store the data for later submission
    if (responseData.sts === "2") {
      console.log("Token expired, generating new token...");
      const genToken = await generateToken();

      console.log({ genToken });
      console.log("Storing data for later submission...");
      // Execute the submission if there is stored data
      responseData = await saveCatagory({ ...catagoryDataedit, ...postdata });
    }

    if (!responseData.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    console.log("catagoryLIst", responseData);

    const notifyfail = () =>
      toast.error(responseData.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    if (responseData.sts === "1") {
      notifyfail();
      return;
    } else if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
    toast.error(error.message, {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  } finally {
    setIsSubmitting(false);
  }
};
