/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;

export const fetchRoleData = async (
  setRoles,
  authToken,
  setModule,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/getRoles", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchRoleData(setRoles, authToken, setModule, false);
        return;
      }
    }

    const modules = await fetch(ADMINURL + "/admin/getModules", {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });

    const modulesData = await modules.json();
    console.log(modulesData, "asdf");
    console.log(responseData);
    var order = modulesData.data.map((mod) => mod.order);
    var module = modulesData.data.map((mod) => mod.module);
    console.log({ module });
    console.log({ order, module });

    if (modulesData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchRoleData(setModule, setRoles, authToken, false);
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }
    var froles = responseData.data.roles;
    var rolescounts = responseData.data.roleCounts;
    var rolesdata = [];
    if (froles.length) {
      var i = 1;
      rolesdata = froles.map((rols) => {
        var modules = rols.moduleslist.map((mod) => mod.module).join(",");
        var users =
          rolescounts && Object.keys(rolescounts).includes(rols._id)
            ? rolescounts[rols._id]
            : 0;
        return { ...rols, module: modules, users, id: i++ };
      });
    }
    setModule(modulesData.data);
    setRoles(rolesdata);
    const rolemod = rolesdata.map((role) => role.moduleslist);
    const initmodule = modulesData.data.map((mod) => mod.module);
    console.log({ initmodule });
    console.log({ rolemod });

    const initmoduleMap = new Map(initmodule.map((mod, index) => [mod, index]));

    const sortedRolemod = [...rolemod].sort(
      (a, b) => initmoduleMap[a] - initmoduleMap[b]
    );
    console.log({ sortedRolemod });

    console.log("Data submitted successfully:", responseData.data);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

const saveRole = async (payLoad) => {
  const authTokenOne = localStorage.getItem("token");
  try {
    var response = await fetch(ADMINURL + "/admin/saveRoles", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokenOne}`,
      },
      body: JSON.stringify(payLoad),
    });
    var responseData = await response.json();
    return responseData;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const roleName = async function catagoryHandler(
  setIsSubmitting,
  notifyfail,
  additionalCheckboxes,
  id,
  name,
  toast,
  notifysuccess,
  Hide,
  setRender,
  authToken,
  err,
  setErr
) {
  var check = 1;
  Object.keys(err).map((errname) => {
    if (
      typeof name !== "string" ||
      (typeof name === "string" && (name.trim() === "" || name === "0"))
    ) {
      check = 0;
      setErr((prev) => ({ ...prev, [errname]: true }));
      console.log(errname, "err");
      console.log(err);
    }
  });
  if (!check) {
    return false;
  }

  if (!additionalCheckboxes.length) {
    setIsSubmitting(false);
    notifyfail();
    return;
  }
  if (additionalCheckboxes.length) {
    var checkfield = additionalCheckboxes.some((mods) => {
      return (
        mods.view === false &&
        mods.add === false &&
        mods.edit === false &&
        mods.delete === false
      );
    });
    if (checkfield) {
      setIsSubmitting(false);
      notifyfail();
      return;
    }
  }
  setIsSubmitting(true);
  try {
    var postdata = id
      ? {
          role: name,
          moduleslist: additionalCheckboxes,
          id: id,
          mode: id ? "update" : "insert",
          status: true,
        }
      : {
          role: name,
          moduleslist: additionalCheckboxes,
          mode: "insert",
          status: true,
        };

    var responseData = await saveRole(postdata);
    console.log("loteryList", responseData);
     // If token expired, regenerate token and store the data for later submission
    if (responseData.sts === "2") {
      console.log("Token expired, generating new token...");
      const genToken = await generateToken();

      console.log({ genToken });
      console.log("Storing data for later submission...");
      // Execute the submission if there is stored data
      responseData = await saveRole(postdata);
    }

    console.log("role", responseData);
    if (responseData.sts === "1") {
      const notifyfail = () =>
        toast.error(responseData.msg, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      notifyfail();
      return;
    }
    if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    setIsSubmitting(false);
    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
  setTimeout(() => {
    setRender((prev) => !prev);
  }, 1000);
};

export const roleDelete = async function handleDeleteClick(
  row,
  notifyfail,
  notifysuccess,
  setRender,
  authToken
) {
  const isConfirmed = window.confirm("Do You Want to Delete this Role?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/saveRoles", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: row._id, mode: "delete" }),
      });
      if (response.status === 200) {
        notifysuccess();
      } else if (response.status === 413) {
        notifyfail();
      } else if (response.status === 500) {
        notifyfail();
      }
      setRender((prev) => !prev);
      // Handle response data as needed
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    }
  }
};
