/* 
Author: Sreenivassan R
*/
import { generateToken } from "./tokenGenerate";
const ADMINURL = process.env.REACT_APP_ADMINURL;

export const fetchSeriesData = async (
  setSeriesData,
  authToken,
  retry = true
) => {
  try {
    const response = await fetch(ADMINURL + "/admin/lotterySeries?mode=all", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authToken}`,
      },
    });
    const responseData = await response.json();

    if (responseData.sts === "2" && retry) {
      const genToken = await generateToken();
      console.log("generated");
      if (genToken === "Token Generated Successfully") {
        const authToken = localStorage.getItem("token");
        console.log({ authToken });
        await fetchSeriesData(setSeriesData, authToken, false);
        return;
      }
    }

    if (!response.ok) {
      throw new Error(
        responseData.message || "Failed to submit data to the server"
      );
    }

    const seriesData = responseData.data.map((item, index) => {
      return {
        _id: item._id,
        id: index + 1,
        name: item.name,
        series: item.series,
        status: item.status,
      };
    });
    console.log("seriesData", responseData);
    setSeriesData(seriesData);
    console.log("Data submitted successfully:", seriesData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  }
};

export const seriesDelete = async function handleDeleteClick(
  row,
  authToken,
  notifysuccess,
  notifyfail,
  setRender
) {
  console.log(row);
  const isConfirmed = window.confirm("Do You Want to Delete this Series?");
  if (isConfirmed) {
    try {
      const response = await fetch(ADMINURL + "/admin/lotterySeries", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({ id: row, mode: "delete" }),
      });
      console.log("response categery", response.status);

      if (response.status === 200) {
        notifysuccess();
      } else if (response.sts === "1") {
        notifyfail();
      }
      setRender((prev) => !prev);
    } catch (error) {
      console.error("Error:", error);
    }
  }
};

const saveSeries = async (payLoad) => {
  console.log({ payLoad });
  const authTokenOne = localStorage.getItem("token");
  try {
    var response = await fetch(ADMINURL + "/admin/lotterySeries", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authTokenOne}`,
      },
      body: JSON.stringify(payLoad),
    });
    var responseData = await response.json();
    return responseData;
  } catch (err) {
    throw new Error(err.message);
  }
};

export const seriesCreate = async function seriesHandler(
  id,
  authToken,
  setSeriesData,
  setIsSubmitting,
  notifysuccess,
  Hide,
  toast,
  seriesData,
  err,
  setErr,
  retry = true
) {
  console.log("seriesData", seriesData);

  var check = 1;
  Object.keys(err).map((errname) => {
    if (
      typeof seriesData[errname] !== "undefined" &&
      ((typeof seriesData[errname] === "string" &&
        seriesData[errname].trim() === "") ||
        seriesData[errname] === "0")
    ) {
      check = 0;
      setErr((prev) => ({ ...prev, [errname]: true }));
      console.log(errname, "err");
      console.log(err);
    }
  });
  if (!check) {
    return false;
  } else {
    setSeriesData((prevFormData) => {
      const { _id, ...rest } = prevFormData;
      return {
        ...rest,
      };
    });
  }
  setIsSubmitting(true);
  try {
    const { _id, ...catagoryDataedit } = seriesData;
    var postdata = id
      ? {
          id: id,
          mode: "update",
        }
      : {
          mode: "insert",
        };
    var responseData = await saveSeries({ ...catagoryDataedit, ...postdata });

    // If token expired, regenerate token and store the data for later submission
    if (responseData.sts === "2") {
      console.log("Token expired, generating new token...");
      const genToken = await generateToken();

      console.log({ genToken });
      console.log("Storing data for later submission...");
      // Execute the submission if there is stored data
      responseData = await saveSeries({ ...catagoryDataedit, ...postdata });
    }

    console.log("catagoryLIst", responseData);

    const notifyfail = () =>
      toast.error(responseData.msg, {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

    if (responseData.sts === "1") {
      notifyfail();
      return;
    } else if (responseData.sts === "0") {
      notifysuccess();
      Hide();
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }

    console.log("Data submitted successfully:", responseData);
  } catch (error) {
    console.error("Error submitting data:", error.message);
  } finally {
    setIsSubmitting(false);
  }
};
