/* 
Author: Sreenivassan R
Module: Create Catagory
*/
import React, { useEffect, useState } from "react";
import ModalComponent from "../UI/ModalComponent";
import CustomInput from "../UI/CustomInput";
import classes from "./CreateCatagory.module.css";
import Button from "../UI/Button";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { catagoryCreate } from "../../util/catagory";
import DropDown from "../UI/DropDown";

function CreateCatagory({ Hide, id, updateCatagoryDataGet }) {
  const [catagoryData, setCatagoryData] = useState({
    categoryname: "",
    subcategoryname: "",
    status: true,
    cardimg: "",
    img: "",
    radialcolor: ["#000", "#000"],
  });
  const [err, setErr] = useState({
    categoryname: false,
    img: false,
    cardimg: false,
    radialcolor: false,
  });
  const authToken = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [subcatagoy, setSubCatoagory] = useState(true);
  const [lotteryCardImage, setLotterycardImage] = useState({
    img: "",
    cardimg: "",
  });

  useEffect(() => {
    if (id) {
      setCatagoryData(updateCatagoryDataGet);
      setLotterycardImage({
        img: updateCatagoryDataGet.imgurl,
        cardimg: updateCatagoryDataGet.cardimgurl,
      });
    }
  }, [updateCatagoryDataGet]);

  /* Getting data from the Lottery Input */
  const dataHandler = (e) => {
    const { name, value, type } = e.target;

    if (err[name] && value) {
      setErr((prev) => ({ ...prev, [name]: false }));
    }

    if (type === "radio" && name === "radio") {
      setCatagoryData((prevState) => ({
        ...prevState,
        status: value === "enable" ? true : false,
      }));
    } else {
      setCatagoryData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (name === "subcategoryname") {
      setSubCatoagory(value.trim() !== "");
    }
  };

  /* To Close the Modal popup */
  const cancelHandler = () => {
    Hide();
  };

  const notifysuccess = () =>
    toast.success("Success", {
      position: "top-right",
      autoClose: 2000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

  function catagoryHandler() {
    // Perform validation
    var check = 1;
    Object.keys(err).forEach((errname) => {
      if (
        typeof catagoryData[errname] !== "undefined" &&
        ((!Array.isArray(catagoryData[errname]) &&
          catagoryData[errname].trim() === "") ||
          catagoryData[errname] === "0" ||
          (typeof catagoryData[errname] === "object" &&
            catagoryData[errname].findIndex((item) => item.trim() === "") >=
              0) ||
          (typeof catagoryData[errname] === "object" &&
            catagoryData[errname].some(
              (it) => !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(it)
            )))
      ) {
        check = 0;
        setErr((prev) => ({ ...prev, [errname]: true }));
        console.log(errname, "err");
      }
    });

    if (!check) {
      return false;
    }

    if (catagoryData.subcategoryname && !subcatagoy) {
      return;
    }

    // Call the create function if validation passes
    catagoryCreate(
      id,
      authToken,
      setCatagoryData,
      setIsSubmitting,
      notifysuccess,
      Hide,
      toast,
      catagoryData,
      subcatagoy
    );
  }

  /* Handler Function to change the color */
  const handleHexColorChange = (event, index) => {
    setCatagoryData((prevState) => {
      var prevRadialcolors = prevState.RadialColor;
      prevRadialcolors[index] = event.target.value;
      return {
        ...prevState,
        RadialColor: prevRadialcolors,
      };
    });
  };
  /* Handler Function to convert image to base64String */
  function imageHandler(e, imgType) {
    const selectedImage = e.target.files[0];

    const reader = new FileReader();

    reader.onload = () => {
      const base64String = reader.result;
      setLotterycardImage((prevState) => ({
        ...prevState,
        [imgType]: base64String,
      }));
      setCatagoryData((prevState) => ({
        ...prevState,
        [imgType]: base64String,
      }));
    };
    if (selectedImage) {
      reader.readAsDataURL(selectedImage);
    } else {
      setLotterycardImage((prevState) => ({ ...prevState, [imgType]: null }));
      setCatagoryData((prevState) => ({
        ...prevState,
        [imgType]: "",
      }));
    }
  }

  /* To Remove Image */
  function handleRemoveImage(imgType) {
    setLotterycardImage((prevState) => ({ ...prevState, [imgType]: "" }));
    setCatagoryData((prevState) => ({
      ...prevState,
      [imgType]: "",
    }));
  }
  var images = { img: "Image", cardimg: "Lottery Card Image" };
  const title = id ? "Edit" : "Create";
  return (
    <ModalComponent Hide={Hide}>
      <div className={classes.modalPadd}>
        <h3>{title} Category</h3>
        <CustomInput
          label="Category"
          placeholder="Category"
          customInput={
            err.categoryname ? classes.errorInput : classes.addCatagory
          }
          name="categoryname"
          value={catagoryData.categoryname}
          onChange={dataHandler}
          err={err.categoryname}
        />
        <CustomInput
          label="Sub Category"
          placeholder="Sub Category"
          customInput={!subcatagoy ? classes.errorInput : classes.addCatagory}
          name="subcategoryname"
          value={catagoryData.subcategoryname}
          onChange={dataHandler}
        />

        {!subcatagoy && (
          <p className={classes.errorMsg}>Please enter Subcatagoy.</p>
        )}
        <div>
          <div className={classes.imageContainer}>
            <label>Category Image</label>
            <div
              className={err.img ? classes.errorInputImg : classes.imagePreview}
            >
              {lotteryCardImage.img && (
                <div className={classes.imageDisplayPreview}>
                  <img
                    src={lotteryCardImage.img}
                    className={classes.UserprofieImage}
                    alt="Profile"
                  />
                  <button
                    onClick={() => handleRemoveImage("img")}
                    className={classes.removeButton}
                  >
                    <FontAwesomeIcon icon={faTimes} className={classes.icon} />
                  </button>
                </div>
              )}
            </div>
            <div className={classes.fileButton}>
              <input
                type="file"
                onChange={(e) => imageHandler(e, "img")}
                accept="image/png"
                name="img"
              />
            </div>
          </div>
          {err.img && (
            <span className={classes.errorMsg}>
              This Image field is required.
            </span>
          )}
        </div>
        <div>
          <div className={classes.imageContainer}>
            <label>Lottery Card Image</label>
            <div
              className={
                err.cardimg ? classes.errorInputImg : classes.imagePreview
              }
            >
              {lotteryCardImage.cardimg && (
                <div className={classes.imageDisplayPreview}>
                  <img
                    src={lotteryCardImage.cardimg}
                    className={classes.UserprofieImage}
                    alt="Profile"
                  />
                  <button
                    onClick={() => handleRemoveImage("cardimg")}
                    className={classes.removeButton}
                  >
                    <FontAwesomeIcon icon={faTimes} className={classes.icon} />
                  </button>
                </div>
              )}
            </div>
            <div className={classes.fileButton}>
              <input
                type="file"
                onChange={(e) => imageHandler(e, "cardimg")}
                accept="image/png"
                name="cardimg"
              />
            </div>
          </div>
          {err.cardimg && (
            <span className={classes.errorMsg}>
              This Image field is required.
            </span>
          )}
        </div>
        <div className={classes.imageContainer}>
          <label htmlFor="colors">Colors</label>
          <div
            style={{ display: "flex", justifyContent: "start", gap: "10px" }}
          >
            {catagoryData.radialcolor.map((color, index) => {
              return (
                <div>
                  <input
                    type="color"
                    id="colorpicker"
                    name="color"
                    value={color}
                    pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                    style={{
                      display: "block",
                      width: "25%",
                      float: "left",
                      height: "43px",
                      borderRadius: "10px 0px 0px 10px",
                      border: "1px solid var(--color-inputBorder)",
                      padding: "5px",
                      outline: !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color)
                        ? "2px solid red"
                        : "none",
                    }}
                    onChange={(e) => handleHexColorChange(e, index)}
                  />
                  <input
                    type="text"
                    id="hexcolor"
                    value={color}
                    pattern="^#+([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$"
                    style={{
                      display: "block",
                      width: "50%",
                      float: "left",
                      height: "43px",
                      padding: "1rem",
                      fontSize: "14px",
                      border: "1px solid var(--color-inputBorder)",
                      borderRadius: "0px 10px 10px 0",
                      outline: !/^#([a-fA-F0-9]{6}|[a-fA-F0-9]{3})$/.test(color)
                        ? "2px solid red"
                        : "none",
                    }}
                    onChange={(e) => handleHexColorChange(e, index)}
                  />
                </div>
              );
            })}
          </div>
          {err["RadialColor"] && (
            <span className={classes.errorMsg}>
              This Colors field is required.
            </span>
          )}
        </div>

        <div className={classes.checkBoxFunction}>
          <input
            type="radio"
            id="option1"
            name="radio"
            value="enable"
            checked={catagoryData.status === true}
            onChange={dataHandler}
          />
          <label htmlFor="option1">Enable</label>

          <input
            type="radio"
            id="option2"
            name="radio"
            value="disable"
            checked={!catagoryData.status}
            onChange={dataHandler}
          />
          <label htmlFor="option2">Disable</label>
        </div>

        <div className={classes.functionButton}>
          <Button
            customButton={classes.resetButton}
            customButtonWidth={classes.button}
            Hide={Hide}
            onClick={cancelHandler}
          >
            Cancel
          </Button>
          <Button
            customButtonWidth={classes.button}
            disabled={isSubmitting}
            onClick={catagoryHandler}
          >
            {isSubmitting ? "Submitting..." : "Save"}
          </Button>
        </div>
      </div>
    </ModalComponent>
  );
}

export default CreateCatagory;
