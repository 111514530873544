/* 
Author: Sreenivassan R
*/
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";
import { Link, Outlet, useNavigate } from "react-router-dom";
import classes from "./RootLayout.module.css";
import HeaderRoot from "./UI/HeaderRoot";
import logo from "../assetes/images/charlesGroupLogo.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, IconButton, Toolbar } from "@mui/material";
import { faCircle } from "@fortawesome/free-regular-svg-icons";
import { svgProfileIcons } from "../assetes/image";
import MenuIcon from "@mui/icons-material/Menu";

const ADMINURL = process.env.REACT_APP_ADMINURL;
const drawerWidth = 240;

export default function ResponsiveDrawer(props) {
  const { window } = props;

  const navigate = useNavigate();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [isClosing, setIsClosing] = React.useState(false);

  const handleDrawerClose = () => {
    setIsClosing(isClosing);
    setMobileOpen(false);
  };

  const handleDrawerTransitionEnd = () => {
    setIsClosing(false);
  };

  const [userrole, setUserrole] = React.useState({});
  const [modules, setModules] = React.useState([]);
  const [active, setActive] = React.useState("");
  const [openReports, setOpenReports] = React.useState(false);
  const [openCMS, setOpenCMS] = React.useState(false);

  const handleItemClickOne = (module, location, state) => {
    navigate(location, { state: state });
    localStorage.setItem("location", location);
    localStorage.setItem("modCurrent", module);
    const modCurrent = localStorage.getItem("modCurrent");
    setActive(modCurrent);
    setOpenReports(false);
     setOpenCMS(false)
  };

  const handleItemClickTwo = (module, location, state) => {
    navigate(location, { state: state });
    localStorage.setItem("location", location);
    localStorage.setItem("modCurrent", module);
    const modCurrent = localStorage.getItem("modCurrent");
    setActive(modCurrent);
  };

  const handleItemClickThree = (module, location, state) => {
    navigate(location, { state: state });
    localStorage.setItem("location", location);
    localStorage.setItem("modCurrent", module);
    const modCurrent = localStorage.getItem("modCurrent");
    setActive(modCurrent);
  };

  function handleReportsClick() {
    setOpenReports(!openReports);
    setOpenCMS(false);
   
  }

  function handleCMSClick() {
    setOpenCMS(!openCMS);
    setOpenReports(false);
 
  }

  const handleDrawerToggle = () => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  };

  function checkActiveReport() {
    const anyReportActive = modules.some(
      (mod) =>
        mod.report &&
        userrole.hasOwnProperty(mod.module) &&
        userrole[mod.module]["view"] &&
        active === mod.module
    );
    setOpenReports(anyReportActive);
  }

  function checkActiveCms() {
    const anyReportActive = modules.some(
      (mod) =>
        mod.cms &&
        userrole.hasOwnProperty(mod.module) &&
        userrole[mod.module]["view"] &&
        active === mod.module
    );
    setOpenCMS(anyReportActive);
  }

  var id = localStorage.getItem("userId");

  React.useEffect(() => {
    async function getRole() {
      var id = localStorage.getItem("userId");
      var location = localStorage.getItem("location");
      const authToken = localStorage.getItem("token");
      try {
        const promises = [
          fetch(ADMINURL + "/admin/getAdmin", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
            body: JSON.stringify({ AdminId: id }),
          }).then((res) => res.json()),
          fetch(ADMINURL + "/admin/getModules", {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${authToken}`,
            },
          }).then((res) => res.json()),
        ];

        const [role, modules] = await Promise.all(promises);
        console.log(role);
        console.log(modules);
        var modulepermissions = {};
        var activeModule = "";
        var firstlink = "";
        var firststate = {};
        var chk = 0;

        role.data.roleId.moduleslist.forEach((list, index) => {
          if (list.view && chk === 0) {
            activeModule = list.module;
            console.log(list);
            firststate = list;
            console.log(list.module);
            chk = 1;
          }

          const modCurrent = localStorage.getItem("modCurrent");
          setActive(modCurrent);

          if (modCurrent) {
            setActive(modCurrent);
          } else {
            setActive(activeModule);
          }

          checkActiveReport();
          checkActiveCms();

          modulepermissions[list.module] = list;
        });

        const activeModuleData = modules.data.find((modu) => {
          console.log(modu.order);
          console.log({ modules });
          return modu.module === activeModule;
        });

        if (activeModuleData) {
          firstlink = activeModuleData.link;
        }

        console.log(firstlink);
        console.log(firststate);
        setModules(modules.data);
        console.log(modules.data.order);
        setUserrole(modulepermissions);
        if (location && location) {
          navigate(location, { state: firststate });
        } else {
          navigate(`/Root/${firstlink}`, { state: firststate });
        }
      } catch (error) {
        console.error("Error fetching dropdown options:", error);
      }
    }
    getRole();
  }, []);

  var setinitial = 0;
  // Filter the modules array to check if there are any valid modules
  const validModules = modules.filter(
    (mod) =>
      mod.cms &&
      userrole.hasOwnProperty(mod.module) &&
      userrole[mod.module]["view"]
  );
  const validReports = modules.filter(
    (mod) =>
      mod.report &&
      userrole.hasOwnProperty(mod.module) &&
      userrole[mod.module]["view"]
  );

  const drawer = (
    <div>
      <div className={classes.logo}>
        <img src={logo} alt={logo} />
      </div>

      {modules.length &&
        modules.map((mod, index) => {
          if (
            userrole.hasOwnProperty(mod.module) &&
            userrole[mod.module]["view"] &&
            !mod.report &&
            !mod.cms
          ) {
            setinitial++;
            let IconMap = {
              DashBoardIcon:
                active === mod.module
                  ? svgProfileIcons.DashBoardIconActive
                  : svgProfileIcons.DashBoardIcon,
              LotteryIcon:
                active === mod.module
                  ? svgProfileIcons.LotteryIconActive
                  : svgProfileIcons.LotteryIcon,
              UserIcon:
                active === mod.module
                  ? svgProfileIcons.UserIconActive
                  : svgProfileIcons.UserIcon,
              RoleIcon:
                active === mod.module
                  ? svgProfileIcons.RoleIconActive
                  : svgProfileIcons.RoleIcon,
              CatagoryIcon:
                active === mod.module
                  ? svgProfileIcons.CatagoryIconActive
                  : svgProfileIcons.CatagoryIcon,
              ResultIcon:
                active === mod.module
                  ? svgProfileIcons.ResultIconActive
                  : svgProfileIcons.ResultIcon,
              BannerIcon:
                active === mod.module
                  ? svgProfileIcons.BannerIconActive
                  : svgProfileIcons.BannerIcon,
              NotificationIcon:
                active === mod.module
                  ? svgProfileIcons.NotificationIconActive
                  : svgProfileIcons.NotificationIcon,
              PromotionIcon:
                active === mod.module
                  ? svgProfileIcons.PromotionIconActive
                  : svgProfileIcons.PromotionIcon,
            };
            let Iconcomponent = IconMap[mod.icon];
            return (
              <>
                <List sx={{ padding: "0 8px" }} key={index}>
                  <ListItem
                    sx={{
                      backgroundImage:
                        active === mod.module
                          ? "linear-gradient(to bottom, #FFBF0C, #FF9D0B)"
                          : "white",
                      padding: "8px",
                      borderRadius: "0.5rem",
                    }}
                    onClick={() =>
                      handleItemClickOne(
                        mod.module,
                        `/Root/${mod.link}`,
                        userrole[mod.module]
                      )
                    }
                  >
                    <ListItemIcon sx={{ minWidth: "33px", height: "25px" }}>
                      <div
                        dangerouslySetInnerHTML={{ __html: Iconcomponent }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      sx={{
                        "& .MuiTypography-root": {
                          fontFamily: "Poppins, sans-serif !important",
                          fontSize: "14px",
                        },
                        color: active === mod.module ? "white" : "black",
                      }}
                    >
                      {mod.module}
                    </ListItemText>
                  </ListItem>
                </List>
              </>
            );
          }
        })}
      {/* Reports */}
      {modules.length > 0 && validReports.length > 0 && (
        <>
          <List sx={{ padding: "0 8px" }}>
            <ListItem
              onClick={handleReportsClick}
              sx={{
                backgroundImage: openReports
                  ? "linear-gradient(to bottom, #FFBF0C, #FF9D0B)"
                  : "white",
                padding: "8px",
                borderRadius: "0.5rem",
                "& .MuiTypography-root": {
                  fontFamily: "Poppins, sans-serif !important",
                  fontSize: "14px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "33px", height: "25px" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: openReports
                      ? svgProfileIcons.ReportsIconActive
                      : svgProfileIcons.ReportsIcon,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: openReports ? "white" : "black",
                }}
                primary="Reports"
              />
              {openReports ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {modules.length > 0 && (
              <Collapse in={openReports} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  sx={{
                    backgroundColor: "#ffbf0c36",
                    borderRadius: "0.5rem",
                    padding: "0 8px",
                    margin: "8px 0 0 ",
                  }}
                >
                  {modules.map((mod) => {
                    //console.log(modules)
                    if (
                      mod.report &&
                      userrole.hasOwnProperty(mod.module) &&
                      userrole[mod.module]["view"]
                    ) {
                      return (
                        <ListItem
                          key={mod.module}
                          sx={{
                            color:
                              active === mod.module ? "#FFBF0C" : "#8E92BC",
                            padding: "8px",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() =>
                            handleItemClickTwo(
                              mod.module,
                              `/Root/${mod.link}`,
                              userrole[mod.module]
                            )
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "33px",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              className={classes.reportIcon}
                              icon={faCircle}
                              color={
                                active == mod.module ? "#FFBF0C" : "#8E92BC"
                              }
                            />
                          </ListItemIcon>
                          <ListItemText>{mod.module}</ListItemText>
                        </ListItem>
                      );
                    }
                    return null;
                  })}
                </List>
              </Collapse>
            )}
          </List>
        </>
      )}
      {/* CMS */}

      {modules.length > 0 && validModules.length > 0 && (
        <>
          <List sx={{ padding: "0 8px" }}>
            <ListItem
              onClick={handleCMSClick}
              sx={{
                backgroundImage: openCMS
                  ? "linear-gradient(to bottom, #FFBF0C, #FF9D0B)"
                  : "white",
                padding: "8px",
                borderRadius: "0.5rem",
                "& .MuiTypography-root": {
                  fontFamily: "Poppins, sans-serif !important",
                  fontSize: "14px",
                },
              }}
            >
              <ListItemIcon sx={{ minWidth: "33px" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: openCMS
                      ? svgProfileIcons.CmsIconActive
                      : svgProfileIcons.CmsIcon,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                sx={{
                  color: openCMS ? "white" : "black",
                }}
                primary="CMS"
              />
              {openCMS ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {modules.length > 0 && (
              <Collapse in={openCMS} timeout="auto" unmountOnExit>
                <List
                  component="div"
                  sx={{
                    backgroundColor: "#ffbf0c36",
                    borderRadius: "0.5rem",
                    padding: "0 8px",
                    margin: "8px 0 0 ",
                  }}
                >
                  {modules.map((mod) => {
                    // console.log(modules.module)
                    //  console.log(modules.cms)
                    if (
                      mod.cms &&
                      userrole.hasOwnProperty(mod.module) &&
                      userrole[mod.module]["view"]
                    ) {
                      return (
                        <ListItem
                          key={mod.module}
                          sx={{
                            color:
                              active === mod.module ? "#FFBF0C" : "#8E92BC",
                            padding: "8px",
                            borderRadius: "0.5rem",
                          }}
                          onClick={() =>
                            handleItemClickThree(
                              mod.module,
                              `/Root/${mod.link}`,
                              userrole[mod.module]
                            )
                          }
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: "33px",
                              justifyContent: "center",
                            }}
                          >
                            <FontAwesomeIcon
                              className={classes.reportIcon}
                              icon={faCircle}
                              color={
                                active == mod.module ? "#FFBF0C" : "#8E92BC"
                              }
                            />
                          </ListItemIcon>
                          <ListItemText>{mod.module}</ListItemText>
                        </ListItem>
                      );
                    }
                    return null;
                  })}
                </List>
              </Collapse>
            )}
          </List>
        </>
      )}
    </div>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Box
        component="nav"
        sx={{ width: { lg: drawerWidth }, flexShrink: { sm: 0, md: 0 } }}
        aria-label="mailbox folders"
        className={classes.tabNav}
      >
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onTransitionEnd={handleDrawerTransitionEnd}
          onClose={handleDrawerClose}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "block", md: "block", lg: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowX: "hidden",
            },
          }}
        >
          {drawer}
        </Drawer>

        {/* Monitor Screen */}
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "none", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              overflowX: "hidden",
            },
            "& .MuiListItem-gutters": {
              cursor: "pointer",
            },
            "& .MuiAppBar-root": {
              flexDirection: "row",
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <AppBar
        position="fixed"
        sx={{
          width: {
            lg: `calc(100% - ${drawerWidth}px)`,
          },
          ml: { md: `${drawerWidth}px` },
          flexGrow: 1,
          backgroundColor: "white",
          height: "60px",
          boxShadow: "1px -1px 8px rgba(0, 0, 0, 0.25);",
        }}
      >
        <Toolbar className={classes.toolDraw}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: "block", lg: "none" } }}
          >
            <MenuIcon sx={{ color: "black" }} />
          </IconButton>
          <Typography className={classes.toolDrawFlex}>
            <HeaderRoot />
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          width: { lg: `calc(100% - ${drawerWidth}px)` },
          padding: "35px 0 0",
        }}
      >
        <Outlet />
      </Box>
    </Box>
  );
}
