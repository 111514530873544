import React, { useState, useRef, useEffect } from "react";
import "./Logs.css";
import Grid from "@mui/material/Grid";
import {
  ChakraProvider,
  Select,
  FormLabel,
  Button,
  TableContainer,
  Thead,
  Tr,
  Td,
  Tbody,
  Table,
  Th,
  Text,
  Box as ChkraBox,
} from "@chakra-ui/react";
import { DateRangePicker } from "rsuite";
import DataGridSetup from "../UI/DataGridSetup.js";
import classes from "./logs.module.css";
import MButton from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import { ByUserName } from "./ByUserName/ByUserName.js";
import { Created } from "./Created/Created.js";
import { DateTime } from "./DateTime/DateTime.js";
import { Deleted } from "./Deleted/Deleted.js";
import { Edited } from "./Edited/Edited.js";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import Box from "@mui/material/Box";
import StepContent from "@mui/material/StepContent";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Connecter from "./connecter.svg";
import DropDown from "../UI/DropDown.js";
import {
  DataGrid,
  GridPagination,
  GridToolbarQuickFilter,
  GridToolbarContainer,
} from "@mui/x-data-grid";

import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
const ADMINURL = process.env.REACT_APP_ADMINURL;
const CustomToolbar = styled(GridToolbarContainer)({
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
  gap: "10px",
  marginBottom: "10px",
});

const CustomQuickFilter = styled(GridToolbarQuickFilter)({
  "& .MuiInputBase-root": {
    backgroundColor: "white",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 25x",
    marginTop: "10px",
    width: "250px",
    // Adjust width as needed
  },
  "& .MuiSvgIcon-root": {
    fontSize: "20px",
  },
});
const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
    left: "calc(-50% + 16px)",
    right: "calc(50% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#784af4",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "grey",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <>
          <div
            className="QontoStepIcon-circle"
            style={{
              marginLeft: "8px",
              width: "1px",
              height: "47px",
              position: "relative",
              left: "0.23rem",
              backgroundColor: "#bdbdbd",
            }}
          />
          <div
            style={{
              position: "relative",
              left: "3px",

              top: "-5px",
            }}
          >
            <img src={Connecter} />
          </div>
        </>
      )}
    </QontoStepIconRoot>
  );
}

const Logs = () => {
  const [date, setDate] = useState({
    fromdates: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    todates: new Date().toISOString().slice(0, 10) + "",
  });
  const [rmodule, setRmodule] = useState("lottery");
  const [loading, setLoading] = useState(false);
  const [griddata, setGriddata] = useState([]);
  const [logdata, setLogdata] = useState({});
  const [open, setOpen] = React.useState(false);
  const [stepperdata, setStepperdata] = React.useState({});
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const captureRef = useRef(null);
  const [image, setImage] = useState(null);

  const captureAndDownloadPDF = async () => {
    const captureElement = captureRef.current;

    // Calculate total height of scrollable content
    const scrollHeight = captureElement.scrollHeight;
    const clientHeight = captureElement.clientHeight;
    const numPages = Math.ceil(scrollHeight / clientHeight);

    // Initialize PDF
    const pdf = new jsPDF("p", "mm", "a4");

    // Function to capture and add page to PDF recursively
    const addPage = async (pageNumber, yOffset) => {
      // Capture the current page
      const canvas = await html2canvas(captureElement, {
        scrollY: yOffset,
        windowHeight: clientHeight,
      });

      // Add page as image to PDF
      if (pageNumber > 1) {
        pdf.addPage();
      }
      const imgData = canvas.toDataURL("image/png");
      const imgHeight = (canvas.height * 210) / canvas.width; // A4 width is 210mm
      pdf.addImage(imgData, "PNG", 0, 0, 210, imgHeight);

      // Check if there are more pages
      if (pageNumber < numPages) {
        await addPage(pageNumber + 1, yOffset + clientHeight);
      }
    };

    // Start capturing and adding pages
    await addPage(1, 0);

    // Save PDF with a dynamic name
    const module = "module"; // Replace with your module name if needed
    const date = new Date();
    const dateString = date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    const timeString = date.toTimeString().split(" ")[0].replace(/:/g, ""); // Format time as HHMMSS
    const fileName = `log_${module}_${dateString}_${timeString}.pdf`;
    pdf.save(fileName);
  };

  const downloadPDF = (imgData) => {
    const pdf = new jsPDF("p", "mm", "a4");
    const imgProps = pdf.getImageProperties(imgData);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
    pdf.addImage(imgData, "PNG", 0, 0, pdfWidth, pdfHeight);
    const date = new Date();
    const dateString = date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
    const timeString = date.toTimeString().split(" ")[0].replace(/:/g, ""); // Format time as HHMMSS
    const fileName = `log_${rmodule}_${dateString}_${timeString}.pdf`;

    pdf.save(fileName);
  };

  function formatDateTime(dt) {
    const StartTime = new Date(dt);
    const startdateOptions = {
      timeZone: "Asia/Kolkata",
      month: "short",
      day: "2-digit",
      year: "numeric",
    };

    const starttimeOptions = {
      timeZone: "Asia/Kolkata",
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    };

    const formattedDate = StartTime.toLocaleDateString(
      "en-US",
      startdateOptions
    );
    const formattedTime = StartTime.toLocaleTimeString(
      "en-US",
      starttimeOptions
    );
    return `${formattedDate}  ${formattedTime}`;
  }
  //const [loaing,setLoading]=useState(false);
  function dateHandler(value, mode) {
    console.log(value);
    var originalDateString = new Date(value[0]);
    var isoDateString = originalDateString.toISOString().substring(0, 10);
    console.log(isoDateString);
    var originalDateString1 = new Date(value[1]);
    var isoDateString1 = originalDateString1.toISOString().substring(0, 10);
    setDate({ fromdates: isoDateString, todates: isoDateString1 });
  }
  const changeModule = (e) => {
    setRmodule(e.target.value);
    setGriddata([]);
  };
  const genReport = async () => {
    setLoading(true);
    const authToken = localStorage.getItem("token");
    try {
      const response = await fetch(ADMINURL + "/report/logs", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          from: new Date(date.fromdates),
          to: new Date(date.todates),
          reporttype: rmodule,
        }),
      });
      const responseData = await response.json();
      let reportData = responseData.reportData;
      var groupedData = {};
      var firstLogdatatoShowTable = [];

      reportData.map((d) => {
        var { logid, logmode } = d;
        var ArrayfieldsList = {
          lottery: { state: "statename", lotterycategoryid: "categoryname" },
          category: {},
          offer: { type: "type" },
          admin: { roleId: "role" },
          role: {},
          result: [],
        };
        var booleanFields = {
          lottery: { hotsale: ["Enabled", "Removed"] },
          category: { status: ["Enable", "disable"] },
          offer: {},
          admin: {},
          role: { status: ["Enable", "disable"] },
          result: {},
        };
        if (!groupedData.hasOwnProperty(logid)) {
          groupedData[logid] = {};
          if (rmodule == "lottery" || rmodule == "result") {
            const ticketStartTime = new Date(d.ticketstartdatetime);
            const startdateOptions = {
              timeZone: "Asia/Kolkata",
              month: "short",
              day: "2-digit",
              year: "numeric",
            };

            const starttimeOptions = {
              timeZone: "Asia/Kolkata",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            };

            const formattedDate = ticketStartTime.toLocaleDateString(
              "en-US",
              startdateOptions
            );
            const formattedTime = ticketStartTime.toLocaleTimeString(
              "en-US",
              starttimeOptions
            );

            //Result Date Time
            const resultStartTime = new Date(d.resultdatetime);
            const resultdateOptions = {
              timeZone: "Asia/Kolkata",
              month: "short",
              day: "2-digit",
              year: "numeric",
            };

            const resulttimeOptions = {
              timeZone: "Asia/Kolkata",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            };
            console.log({ d });
            var lotterycategoryname = d.lotterycategoryid[0]?.categoryname;
            lotterycategoryname = lotterycategoryname
              ? lotterycategoryname
              : "";
            //  const catagoryName = item.lotterycategoryid[0].categoryname;

            const formattedResultDate = resultStartTime.toLocaleDateString(
              "en-US",
              resultdateOptions
            );
            const formattedResultTime = resultStartTime.toLocaleTimeString(
              "en-US",
              resulttimeOptions
            );

            var rd = {
              LottId: d.id,
              id: d.logid,
              name: d.name,
              mode: d.logmode,
              user: d.logadminid.length
                ? d.logadminid[0]["username"]
                : "Not Known",
              ticketstartdatetime: `${formattedDate}  ${formattedTime}`,
              resultdatetime: `${formattedResultDate}  ${formattedResultTime}`,
              cost: d.cost,
              lotterycategoryname: lotterycategoryname,
              lotterystatus: d.lotterystatus,
            };
          } else if (rmodule == "category") {
            var rd = {
              id: d.logid,
              category: d.categoryname,
              color: d.color,
              user: d.logadminid[0]["username"],
              subcategoryname: d.subcategoryname,
              status: d.status,
              mode: d.logmode,
            };
          } else if (rmodule == "admin") {
            var rd = {
              id: d.logid,
              name: d.name,
              mobileno: d.mobileno,
              DOB: d.DOB,
              address: d.address,
              adhaarno: d.adhaarno,
              empId: d.empId,
              user: d.logadminid[0]["username"],
              mode: d.logmode,
              username: d.username,
            };
          } else if (rmodule == "role") {
            var rd = {
              id: d.logid,
              role: d.role,
              status: d.status ? "Enabled" : "Disabled",
              user: d.logadminid[0]["username"],
              mode: d.logmode,
            };
          } else if (rmodule == "offer") {
            const StartTime = new Date(d.start);
            const startdateOptions = {
              timeZone: "Asia/Kolkata",
              month: "short",
              day: "2-digit",
              year: "numeric",
            };

            const starttimeOptions = {
              timeZone: "Asia/Kolkata",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            };

            const formattedDate = StartTime.toLocaleDateString(
              "en-US",
              startdateOptions
            );
            const formattedTime = StartTime.toLocaleTimeString(
              "en-US",
              starttimeOptions
            );

            //Result Date Time
            const endTime = new Date(d.end);
            const resultdateOptions = {
              timeZone: "Asia/Kolkata",
              month: "short",
              day: "2-digit",
              year: "numeric",
            };

            const resulttimeOptions = {
              timeZone: "Asia/Kolkata",
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            };
            console.log({ d });

            //  const catagoryName = item.lotterycategoryid[0].categoryname;

            const formattedEnd = endTime.toLocaleDateString(
              "en-US",
              resultdateOptions
            );
            const formattedEndTime = endTime.toLocaleTimeString(
              "en-US",
              resulttimeOptions
            );

            var rd = {
              id: d.logid,
              code: d.code,
              mode: d.logmode,
              user: d.logadminid[0]["username"],
              start: `${formattedDate}  ${formattedTime}`,
              end: `${formattedEnd}  ${formattedEndTime}`,
              description: d.description,
            };
          }

          firstLogdatatoShowTable.push(rd);
        }
        if (!groupedData[logid].hasOwnProperty(logmode)) {
          groupedData[logid][logmode] = [];
        }
        var spredObj = {};
        Object.keys(ArrayfieldsList[rmodule]).map((arfld) => {
          spredObj[arfld] = d[arfld][0][ArrayfieldsList[rmodule][arfld]];
        });
        var booolspredobj = {};
        Object.keys(booleanFields[rmodule]).map((boolflds) => {
          booolspredobj[boolflds] = d[boolflds]
            ? booleanFields[rmodule][0]
            : booleanFields[rmodule][1];
        });
        console.log({ spredObj });
        groupedData[logid][logmode].push({
          ...d,
          ...spredObj,
          ...booolspredobj,
        });
      });
      console.log({ groupedData, firstLogdatatoShowTable });
      setLoading(false);
      setGriddata(firstLogdatatoShowTable);
      setLogdata(groupedData);
    } catch (err) {
      console.log(err);
    }
  };
  var dropDownLabel = {
    lottery: "Lottery Management",
    result: "Result Management",
    category: "Category Management",
    admin: "User Management",
    offer: "Promotions Management",
    role: "Role Management",
  };
  let columns = {
    lottery: [
      {
        field: "name",
        headerName: "Lottery Name",
        width: 250,
      },
      { field: "lotterycategoryname", headerName: "Category", width: 150 },
      {
        field: "ticketstartdatetime",
        headerName: "Date",
        width: 200,
      },
      // { field: "mode", headerName: "Log Mdoe", width: 100 },
      // {
      //   field: "user",
      //   headerName: "User",
      //   width: 150,
      // },
      { field: "cost", headerName: "Ticket Price", width: 200 },

      {
        field: "resultdatetime",
        headerName: "Results",
        width: 150,
      },

      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
          <button
            className="log_table_button"
            onClick={() => renderSidebar(params.row.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              ></path>
            </svg>
            <div className="log_table_text">View Log</div>
          </button>
        ),
      },
    ],
    result: [
      {
        field: "name",
        headerName: "Lottery Name",
        width: 250,
      },
      { field: "lotterycategoryname", headerName: "Category", width: 100 },
      {
        field: "ticketstartdatetime",
        headerName: "Date",
        width: 200,
      },
      // { field: "mode", headerName: "Log Mdoe", width: 100 },
      // {
      //   field: "user",
      //   headerName: "User",
      //   width: 150,
      // },
      { field: "cost", headerName: "Ticket Price", width: 80 },

      {
        field: "resultdatetime",
        headerName: "Results",
        width: 150,
      },

      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
          <button
            className="log_table_button"
            onClick={() => renderSidebar(params.row.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              ></path>
            </svg>
            <div className="log_table_text">View Log</div>
          </button>
        ),
      },
    ],
    offer: [
      {
        field: "code",
        headerName: "Coupon Code",
        width: 250,
      },
      { field: "description", headerName: "Description", width: 100 },
      {
        field: "start",
        headerName: "Start Date time",
        width: 200,
      },
      {
        field: "end",
        headerName: "End Date time",
        width: 200,
      },

      { field: "mode", headerName: "Log Mdoe", width: 100 },
      {
        field: "user",
        headerName: "User",
        width: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
          <button
            className="log_table_button"
            onClick={() => renderSidebar(params.row.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              ></path>
            </svg>
            <div className="log_table_text">View Log</div>
          </button>
        ),
      },
    ],
    admin: [
      {
        field: "name",
        headerName: "Admin Name",
        width: 250,
      },
      { field: "mobileno", headerName: "Mobile No", width: 100 },
      {
        field: "address",
        headerName: "Address",
        width: 200,
      },
      {
        field: "adhaarno",
        headerName: "Adhaar No",
        width: 200,
      },
      {
        field: "empId",
        headerName: "Employye Id",
        width: 200,
      },
      {
        field: "username",
        headerName: "User Name",
        width: 200,
      },
      { field: "mode", headerName: "Log Mdoe", width: 100 },
      {
        field: "user",
        headerName: "User",
        width: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
          <button
            className="log_table_button"
            onClick={() => renderSidebar(params.row.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              ></path>
            </svg>
            <div className="log_table_text">View Log</div>
          </button>
        ),
      },
    ],
    role: [
      { field: "role", headerName: "Role Name", width: 200 },
      {
        field: "status",
        headerName: "Role Status",
        width: 200,
      },
      { field: "mode", headerName: "Log Mdoe", width: 100 },
      {
        field: "user",
        headerName: "User",
        width: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
          <button
            className="log_table_button"
            onClick={() => renderSidebar(params.row.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              ></path>
            </svg>
            <div className="log_table_text">View Log</div>
          </button>
        ),
      },
    ],
    category: [
      {
        field: "category",
        headerName: "Category Name",
        width: 250,
      },
      { field: "color", headerName: "Color", width: 100 },
      {
        field: "subcategoryname",
        headerName: "Sub Categoryname",
        width: 200,
      },
      { field: "mode", headerName: "Log Mdoe", width: 100 },
      {
        field: "user",
        headerName: "User",
        width: 150,
      },
      {
        field: "actions",
        headerName: "Actions",
        width: 200,
        renderCell: (params) => (
          <button
            className="log_table_button"
            onClick={() => renderSidebar(params.row.id)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              ></path>
            </svg>
            <div className="log_table_text">View Log</div>
          </button>
        ),
      },
    ],
  };
  const getHeightLength = (lnth) => {
    return lnth * 45 + 80 + "px";
  };
  const renderSidebar = (id) => {
    setOpen(true);
    var fieldsList = {
      lottery: [
        "name",
        "cost",
        "description",
        "hotsale",
        "hotSaleAmount",
        "hotsaleenddatetime",
        "hotsalestartdatetime",
        "noOfDraw",
        "resultdatetime",
        "session",
        "state",
        "ticketenddatetime",
        "ticketscount",
        "ticketstartdatetime",
        "lotterycategoryid",
        "imgurl",
        "signurl",
        "prizelist",
        "combinationmatrics",
        "availableInfo",
      ],
      category: [
        "categoryname",
        "color",
        "subcategoryname",
        "imgurl",
        "status",
      ],
      offer: [
        "code",
        "description",
        "termsandconditions",
        "start",
        "end",
        "minamount",
        "noofdays",
        "nooftickets",
        "targetuser",
        "valuetype",
      ],
      admin: [
        "name",
        "username",
        "DOB",
        "address",
        "adhaarno",
        "city",
        "country",
        "email",
        "emgcontactname",
        "emgcontactnumber",
        "empId",
        "gender",
        "imgurl",
        "mobileno",
        "nationality",
        "pincode",
        "roleId",
        "state",
      ],
      role: ["role", "status", "moduleslist"],
      result: ["name", "winningcombination", "resultdatetime", "lotterystatus"],
    };
    var filedDescription = {
      lottery: [
        "Lottery Name",
        "Lottery Price",
        "Lottery Description",
        "Hotsale",
        "Hotsale Amount",
        "Hotsale Start date time",
        "Hotsale End date time",
        "Draw Number",
        "Result Date time",
        "Session",
        "state",
        "Lottery End Datetime",
        "Total Tickets",
        "Lottery Start Datetime",
        "Category Name",
        "Lottery Image",
        "Lottery Signature",
        "Prize List",
        "Numbering",
        "availableInfo",
      ],
      category: [
        "Category name",
        "Color",
        "Sub Categoryname",
        "Category Image",
        "Status",
      ],
      offer: [
        "Coupon code",
        "Description",
        "Terms and Conditions",
        "start Date Time",
        "end date time",
        "Min Amount Spend",
        "No Of Days Required",
        "No of Tickets bought",
        "Target Audience",
        "Mode of Offer",
      ],
      admin: [
        "Admin name",
        "User Name",
        "Date Of Birth",
        "Address",
        "Adhaar No",
        "City",
        "Country",
        "Email",
        "Emgcontactname",
        "Emgcontactnumber",
        "Employyee Id",
        "Gender",
        "Profile Image",
        "Mobile No",
        "Nationality",
        "Pincode",
        "User Role",
        "State",
      ],
      role: ["Role Name", "Status", "Module Permissions"],
      result: [
        "Lottery Name",
        "Winner Draws",
        "Result Date time",
        "Lottery Status",
      ],
    };
    var imagfields = {
      lottery: ["imgurl", "signurl"],
      category: ["imgurl"],
      offer: [],
      admin: ["imgurl"],
      role: [],
      result: [],
    };
    var tableFields = {
      lottery: ["prizelist"],
      offer: [],
      category: [],
      admin: [],
      role: ["moduleslist"],
      result: [],
    };
    var compareTwoFields = { lottery: { combinationmatrics: "availableInfo" } };
    var datefields = {
      lottery: [
        "ticketstartdatetime",
        "ticketenddatetime",
        "resultdatetime",
        "hotsalestartdatetime",
        "hotsaleenddatetime",
      ],
      offer: [],
      category: [],
      admin: [],
      role: ["moduleslist"],
      result: [],
    };

    var indv_logdata = logdata[id];
    var prevData = {};
    var curData = {};
    var changes = {};
    if (Object.keys(indv_logdata).length) {
      ["insert", "update", "delete"].map((mode) => {
        if (mode !== "update") {
          if (indv_logdata.hasOwnProperty(mode)) {
            changes[mode] = [
              {
                username: indv_logdata[mode][0]["logadminid"][0]["username"],
                logdatetime: indv_logdata[mode][0]["logdatetime"],
                mentionname: indv_logdata[mode][0][fieldsList[rmodule][0]],
                changes: [],
              },
            ];
            mode == "insert" &&
              fieldsList[rmodule].map((fld) => {
                prevData[fld] = indv_logdata[mode][0][fld];
              });
            console.log("insert", prevData);
          }
        } else {
          changes[mode] = [];
          if (indv_logdata.hasOwnProperty(mode)) {
            indv_logdata[mode].map((updata) => {
              var internalechanges = [];
              if (!Object.keys(prevData).length) {
                fieldsList[rmodule].map((fld) => {
                  prevData[fld] = indv_logdata[mode][0][fld];
                });
              } else {
                fieldsList[rmodule].map((fld) => {
                  console.log(
                    JSON.stringify(prevData[fld]),
                    JSON.stringify(updata[fld])
                  );

                  var prev = prevData[fld];
                  var cur = updata[fld];

                  if (rmodule == "lottery" && fld == "combinationmatrics") {
                    prev = { a: prevData[fld], b: prevData["availableInfo"] };
                    cur = { a: updata[fld], b: updata["availableInfo"] };
                  }

                  if (
                    JSON.stringify(prev) !== JSON.stringify(cur) &&
                    fld != "availableInfo"
                  ) {
                    console.log(imagfields[rmodule]);
                    var imgfieldsts = imagfields[rmodule].includes(fld)
                      ? true
                      : false;
                    var tablefieldsts = false;
                    var listfieldsts = false;
                    if (datefields[rmodule].includes(fld)) {
                      prev = formatDateTime(prev);
                      cur = formatDateTime(cur);
                    }
                    if (fld == "winningcombination") {
                      prev = Object.keys(prev).length
                        ? "Lottery draw upload saved"
                        : "Lottery draw not uploaded";
                      cur = Object.keys(prev).length
                        ? prev === "Lottery draw upload saved"
                          ? "Lottery draw reuploaded"
                          : "Lottery draw upload saved"
                        : "Lottery draw removed";
                    }
                    if (fld == "lotterystatus") {
                      prev =
                        prev == "Pending"
                          ? "Lottery on sale"
                          : "Lottery was drawn";
                      cur =
                        cur == "Pending"
                          ? "Lottery on sale"
                          : "Lottery was drawn";
                    }

                    var from = prev;
                    var to = cur;
                    console.log(tableFields[rmodule]);
                    if (tableFields[rmodule].includes(fld)) {
                      tablefieldsts = true;
                      if (fld == "prizelist") {
                        var tableHeaderData = [
                          "digits",
                          "prize Name",
                          "Draw Count",
                          "Prize",
                        ];
                        var tablefields = [
                          "digits",
                          "prizeName",
                          "drawcount",
                          "prize",
                        ];

                        var s = [prevData[fld], updata[fld]].map(
                          (prz, przindex) => {
                            if (!przindex) {
                              from = prz.map((inprz) => {
                                return tablefields.map((tabflds) => {
                                  return {
                                    value: inprz[tabflds],
                                    change: 0,
                                    isBoolean: false,
                                    isDigit: tabflds == "digits" ? true : false,
                                  };
                                });
                              });
                              from = [tableHeaderData, ...from];
                            } else {
                              to = prz.map((inprz, innerIndex) => {
                                return tablefields.map((tabflds, tabindex) => {
                                  var change = 0;
                                  if (from.hasOwnProperty(innerIndex + 1)) {
                                    if (
                                      JSON.stringify(
                                        from[innerIndex + 1][tabindex].value
                                      ) !== JSON.stringify(inprz[tabflds])
                                    ) {
                                      console.log({ tabflds });
                                      change = 1;
                                    }
                                  } else {
                                    change = 1;
                                  }
                                  console.log({ change });
                                  return {
                                    value: inprz[tabflds],
                                    change,
                                    isBoolean: false,
                                    isDigit: tabflds == "digits" ? true : false,
                                  };
                                });
                              });
                              to = [tableHeaderData, ...to];
                            }
                          }
                        );
                      } else if (fld == "moduleslist") {
                        var tableHeaderData = [
                          "Module",
                          "View",
                          "Add",
                          "Edit",
                          "Delete",
                        ];
                        var tablefields = [
                          "module",
                          "view",
                          "add",
                          "edit",
                          "delete",
                        ];
                        [prevData[fld], updata[fld]].map((prz, przindex) => {
                          if (!przindex) {
                            from = prz.map((inprz) => {
                              return tablefields.map((tabflds) => {
                                return {
                                  value: inprz[tabflds],
                                  change: 0,
                                  isDigit: false,
                                  isBoolean: tabflds != "module" ? true : false,
                                };
                              });
                            });
                            from = [tableHeaderData, ...from];
                          } else {
                            to = prz.map((inprz, innerIndex) => {
                              return tablefields.map((tabflds, tabindex) => {
                                var change = 0;
                                if (from.hasOwnProperty(innerIndex + 1)) {
                                  if (
                                    JSON.stringify(
                                      from[innerIndex + 1][tabindex].value
                                    ) !== JSON.stringify(inprz[tabflds])
                                  ) {
                                    console.log({ tabflds });
                                    change = 1;
                                  }
                                } else {
                                  change = 1;
                                }

                                return {
                                  value: inprz[tabflds],
                                  change,
                                  isDigit: false,
                                  isBoolean: tabflds != "module" ? true : false,
                                };
                              });
                            });
                            to = [tableHeaderData, ...to];
                          }
                        });
                      }
                      console.log({ from, to });
                    }
                    if (rmodule == "lottery" && fld == "combinationmatrics") {
                      listfieldsts = true;
                      var totalTickets = { from: [], to: [] };
                      var newObj = { from, to };
                      ["from", "to"].map((objname) => {
                        var already = [];
                        newObj[objname].a.map((matric, index) => {
                          var checkinsideavailable = false;
                          var grpIndex = 0;
                          newObj[objname].b.map(({ digits, range }) => {
                            var [start, much] = digits.split("-");
                            var [froms, tos] = range.split("-");

                            var indexs = Array.from(
                              { length: +much },
                              (_, inx) => Number(start) + Number(inx)
                            );

                            if (indexs.includes(index)) {
                              if (
                                already.some((al) => {
                                  return indexs.includes(al);
                                })
                              ) {
                                checkinsideavailable = true;
                              } else {
                                if (objname == "from") {
                                  totalTickets[objname].push({
                                    value: `${froms}-${tos}`,
                                    change: 0,
                                  });

                                  checkinsideavailable = true;
                                  already.push(...indexs);
                                } else {
                                  var changehappen = 1;
                                  if (
                                    totalTickets["from"].hasOwnProperty(
                                      grpIndex
                                    )
                                  ) {
                                    if (
                                      totalTickets["from"][grpIndex].value ===
                                      `${froms}-${tos}`
                                    ) {
                                      changehappen = 0;
                                    }
                                  }
                                  totalTickets[objname].push({
                                    value: `${froms}-${tos}`,
                                    change: changehappen,
                                  });
                                  grpIndex++;
                                  checkinsideavailable = true;
                                  already.push(...indexs);
                                }
                              }
                            }
                          });
                          if (!checkinsideavailable) {
                            if (objname == "from") {
                              totalTickets[objname].push({
                                value: matric.join(","),
                                change: 0,
                              });
                            } else {
                              var changehappen = 1;
                              if (
                                totalTickets["from"].hasOwnProperty(grpIndex)
                              ) {
                                if (
                                  totalTickets["from"][grpIndex].value ===
                                  matric.join(",")
                                ) {
                                  changehappen = 0;
                                }
                              }
                              totalTickets[objname].push({
                                value: matric.join(","),
                                change: changehappen,
                              });
                            }
                          }
                        });
                      });
                      var { from, to } = totalTickets;
                      console.log({ from, to, matic: "change" });
                    }
                    internalechanges.push({
                      imgfieldsts,
                      tablefieldsts,
                      listfieldsts,
                      fromlabel:
                        filedDescription[rmodule][
                          fieldsList[rmodule].indexOf(fld)
                        ],
                      from,
                      tolabel: `${
                        filedDescription[rmodule][
                          fieldsList[rmodule].indexOf(fld)
                        ]
                      } Changes`,
                      to,
                    });
                  }
                  prevData[fld] = updata[fld];
                });
              }
              changes[mode].push({
                username: indv_logdata[mode][0]["logadminid"][0]["username"],
                logdatetime: indv_logdata[mode][0]["logdatetime"],
                mentionname: indv_logdata[mode][0][fieldsList[rmodule][0]],
                changes: internalechanges,
              });
            });
          }
        }
      });
    }

    console.log({ changes });
    setStepperdata(changes);
  };
  const CustomStepIcon = styled(StepLabel)(({ theme }) => ({
    root: {
      display: "none", // Hide the step number
    },
  }));

  return (
    <div style={{ marginTop: "50px", marginLeft: "10px" }}>
      <Drawer
        open={open}
        anchor={"right"}
        sx={{
          "& .MuiDrawer-paper": {
            backgroundColor: "#f5f5f5",
          },
        }}
        onClose={toggleDrawer(false)}
      >
        <div
          style={{
            width: "450px",
            padding: "20px 40px",
            background: "#f5f5f5",
          }}
          ref={captureRef}
        >
          <Grid container spacing={2} style={{ padding: "10px 30px" }}>
            <Grid xs={6}>
              <h4>Activity Log</h4>
            </Grid>
          </Grid>
          <Box style={{ height: "400px !important" }}>
            <Stepper orientation="vertical">
              {Object.keys(stepperdata).map((mode, index) => {
                if (mode == "insert") {
                  return (
                    <Step key={index} active={true}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <div className={classes.frame26082332}>
                          <Created />
                          <ByUserName
                            username={stepperdata[mode][0]["username"]}
                          />
                          <DateTime
                            datetime={stepperdata[mode][0]["logdatetime"]}
                          />
                        </div>
                      </StepLabel>
                      <StepContent>
                        <Box>
                          <div className={classes.group7706714}>
                            <div className={classes.rectangle59265}></div>
                            <div
                              className={classes.createdHeadingOfTheActionConte}
                            >
                              <div className={classes.textBlock34}>Created</div>
                              <div className={classes.textBlock35}>
                                <p className={classes.labelWrapper15}>
                                  <span className={classes.label20}>
                                    -{rmodule == "result" ? "Lottery" : rmodule}{" "}
                                    created
                                  </span>
                                </p>
                              </div>
                              <ul className={classes.list14}>
                                <li>
                                  <div className={classes.textBlock36}>
                                    <p className={classes.labelWrapper16}>
                                      <span className={classes.label21}>
                                        {stepperdata[mode][0]["mentionname"]}
                                      </span>
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className={classes.rectangle59275}></div>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  );
                } else if (mode == "update") {
                  return stepperdata[mode].map((updata, sindex) => {
                    console.log({ updata });
                    if (!updata.changes.length) {
                      return null;
                    }
                    return (
                      <Step key={sindex} active={true}>
                        <StepLabel StepIconComponent={QontoStepIcon}>
                          <div className={classes.frame26082332}>
                            <Edited />
                            <ByUserName username={updata["username"]} />
                            <DateTime datetime={updata["logdatetime"]} />
                          </div>
                        </StepLabel>

                        <StepContent>
                          {updata["changes"].map((chng) => {
                            return (
                              <Box style={{ marginBottom: "15px" }}>
                                <div
                                  className={
                                    !chng.imgfieldsts
                                      ? chng.tablefieldsts
                                        ? classes.group7706712_max1
                                        : chng.listfieldsts
                                        ? classes.group7706712_max
                                        : classes.group7706712
                                      : classes.group7706712_max
                                  }
                                  style={
                                    chng.tablefieldsts
                                      ? {
                                          height: getHeightLength(
                                            chng.from.length + chng.to.length
                                          ),
                                        }
                                      : undefined
                                  }
                                >
                                  <div className={classes.rectangle59263}></div>
                                  <div className={classes.rectangle59273}></div>
                                  <div className={classes.frame26082372}>
                                    <div
                                      className={
                                        classes.toResultDateAndTimeChange12Jul
                                      }
                                    >
                                      <div className={classes.textBlock10}>
                                        To
                                      </div>
                                      <ul className={classes.list6}>
                                        <li>
                                          <div className={classes.textBlock11}>
                                            -{chng.tolabel}
                                          </div>
                                        </li>
                                      </ul>
                                      <ul className={classes.list7}>
                                        <li>
                                          <div className={classes.textBlock12}>
                                            {chng.tablefieldsts ? (
                                              <div className="hello">
                                                <ChakraProvider>
                                                  <ChkraBox
                                                    overflowX="auto"
                                                    maxW="95%"
                                                  >
                                                    <ChkraBox
                                                      minW="300px"
                                                      position="relative"
                                                    >
                                                      <TableContainer>
                                                        <Table
                                                          variant="simple"
                                                          size="sm"
                                                          colorScheme="gray"
                                                        >
                                                          {chng.to.map(
                                                            (
                                                              tabledata,
                                                              tableIndex
                                                            ) => {
                                                              if (
                                                                tableIndex == 0
                                                              ) {
                                                                return (
                                                                  <Thead>
                                                                    <Tr>
                                                                      {tabledata.map(
                                                                        (
                                                                          header,
                                                                          thIndex
                                                                        ) => {
                                                                          if (
                                                                            thIndex ==
                                                                            0
                                                                          ) {
                                                                            return (
                                                                              <Th
                                                                                position="sticky"
                                                                                left={
                                                                                  0
                                                                                }
                                                                                bg="white"
                                                                                zIndex={
                                                                                  1
                                                                                }
                                                                              >
                                                                                <Text
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "8px",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    header
                                                                                  }
                                                                                </Text>
                                                                              </Th>
                                                                            );
                                                                          } else {
                                                                            return (
                                                                              <Th>
                                                                                <Text
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "8px",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    header
                                                                                  }
                                                                                </Text>
                                                                              </Th>
                                                                            );
                                                                          }
                                                                        }
                                                                      )}
                                                                    </Tr>
                                                                  </Thead>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                          <Tbody>
                                                            {chng.to.map(
                                                              (
                                                                tabledata1,
                                                                tableIndex1
                                                              ) => {
                                                                if (
                                                                  tableIndex1 >
                                                                  0
                                                                ) {
                                                                  return (
                                                                    <Tr>
                                                                      {tabledata1.map(
                                                                        (
                                                                          d,
                                                                          tdIndex
                                                                        ) => {
                                                                          if (
                                                                            d.isDigit
                                                                          ) {
                                                                            return (
                                                                              <Td
                                                                                position="sticky"
                                                                                left={
                                                                                  0
                                                                                }
                                                                                bg="white"
                                                                                zIndex={
                                                                                  1
                                                                                }
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    display:
                                                                                      "flex",
                                                                                    gridGap:
                                                                                      "2px",
                                                                                  }}
                                                                                >
                                                                                  {d.value.map(
                                                                                    (
                                                                                      val,
                                                                                      digitIndex
                                                                                    ) => {
                                                                                      return (
                                                                                        <div
                                                                                          key={
                                                                                            digitIndex
                                                                                          }
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              classes.ticketUnit
                                                                                            }
                                                                                          >
                                                                                            X
                                                                                          </div>
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                              val ===
                                                                                              1
                                                                                            }
                                                                                            style={{
                                                                                              width:
                                                                                                "10px",
                                                                                            }}
                                                                                            disabled
                                                                                          />
                                                                                        </div>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                </div>
                                                                              </Td>
                                                                            );
                                                                          } else {
                                                                            if (
                                                                              tdIndex ==
                                                                              0
                                                                            ) {
                                                                              return (
                                                                                <Td
                                                                                  position="sticky"
                                                                                  left={
                                                                                    0
                                                                                  }
                                                                                  bg="white"
                                                                                  zIndex={
                                                                                    1
                                                                                  }
                                                                                >
                                                                                  <p
                                                                                    className={
                                                                                      d.change
                                                                                        ? classes.label5
                                                                                        : classes.label8
                                                                                    }
                                                                                  >
                                                                                    <Text fontSize="10px">
                                                                                      {
                                                                                        d.value
                                                                                      }
                                                                                    </Text>
                                                                                  </p>
                                                                                </Td>
                                                                              );
                                                                            } else {
                                                                              return (
                                                                                <Td>
                                                                                  <p
                                                                                    className={
                                                                                      d.change
                                                                                        ? classes.label5
                                                                                        : classes.label8
                                                                                    }
                                                                                  >
                                                                                    <Text fontSize="9x">
                                                                                      {d.isBoolean ? (
                                                                                        d.value ? (
                                                                                          <DoneAllIcon
                                                                                            style={{
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          />
                                                                                        ) : (
                                                                                          <CloseIcon
                                                                                            style={{
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          />
                                                                                        )
                                                                                      ) : (
                                                                                        d.value
                                                                                      )}
                                                                                    </Text>
                                                                                  </p>
                                                                                </Td>
                                                                              );
                                                                            }
                                                                          }
                                                                        }
                                                                      )}
                                                                    </Tr>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </Tbody>
                                                        </Table>
                                                      </TableContainer>
                                                    </ChkraBox>
                                                  </ChkraBox>
                                                </ChakraProvider>
                                              </div>
                                            ) : chng.listfieldsts ? (
                                              <>
                                                <ul className={classes.list11}>
                                                  {chng.to.map((li) => {
                                                    return (
                                                      <li>
                                                        <div
                                                          className={
                                                            classes.textBlock18
                                                          }
                                                        >
                                                          <p
                                                            className={
                                                              classes.labelWrapper7
                                                            }
                                                          >
                                                            {li.change ? (
                                                              <span
                                                                className={
                                                                  classes.label9
                                                                }
                                                              >
                                                                {li.value}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className={
                                                                  classes.label10
                                                                }
                                                              >
                                                                {" "}
                                                                {li.value}
                                                              </span>
                                                            )}
                                                          </p>
                                                        </div>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </>
                                            ) : (
                                              <p
                                                className={
                                                  classes.labelWrapper5
                                                }
                                              >
                                                <span
                                                  className={classes.label5}
                                                >
                                                  {chng.imgfieldsts ? (
                                                    <div
                                                      style={{
                                                        height: "55px",
                                                        width: "60px",
                                                        backgroundColor:
                                                          "#D9D9D9",
                                                        display: "grid",
                                                        placeItems: "center",
                                                        borderRadius: "5%",
                                                      }}
                                                    >
                                                      {" "}
                                                      <img
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                        }}
                                                        src={chng.to}
                                                      />{" "}
                                                    </div>
                                                  ) : (
                                                    chng.to
                                                  )}
                                                </span>{" "}
                                              </p>
                                            )}
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                    <div className={classes.divider2}></div>
                                    <div
                                      className={
                                        classes.fromResultDateAndTime10July103
                                      }
                                    >
                                      <div className={classes.textBlock13}>
                                        From
                                      </div>
                                      <ul className={classes.list8}>
                                        <li>
                                          <div className={classes.textBlock14}>
                                            -{chng.fromlabel}
                                          </div>
                                        </li>
                                      </ul>
                                      <ul className={classes.list7}>
                                        <li>
                                          <div className={classes.textBlock12}>
                                            {chng.tablefieldsts ? (
                                              <div>
                                                <ChakraProvider>
                                                  <ChkraBox
                                                    overflowX="auto"
                                                    maxW="95%"
                                                  >
                                                    <ChkraBox
                                                      minW="300px"
                                                      position="relative"
                                                    >
                                                      <TableContainer>
                                                        <Table
                                                          variant="simple"
                                                          size="sm"
                                                          colorScheme="gray"
                                                        >
                                                          {chng.from.map(
                                                            (
                                                              tabledata,
                                                              tableIndex
                                                            ) => {
                                                              if (
                                                                tableIndex == 0
                                                              ) {
                                                                return (
                                                                  <Thead>
                                                                    <Tr>
                                                                      {tabledata.map(
                                                                        (
                                                                          header,
                                                                          thIndex
                                                                        ) => {
                                                                          if (
                                                                            !thIndex
                                                                          ) {
                                                                            return (
                                                                              <Th
                                                                                position="sticky"
                                                                                left={
                                                                                  0
                                                                                }
                                                                                bg="white"
                                                                                zIndex={
                                                                                  1
                                                                                }
                                                                              >
                                                                                <Text
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "8px",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    header
                                                                                  }
                                                                                </Text>
                                                                              </Th>
                                                                            );
                                                                          } else {
                                                                            return (
                                                                              <Th>
                                                                                <Text
                                                                                  style={{
                                                                                    fontSize:
                                                                                      "8px",
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    header
                                                                                  }
                                                                                </Text>
                                                                              </Th>
                                                                            );
                                                                          }
                                                                        }
                                                                      )}
                                                                    </Tr>
                                                                  </Thead>
                                                                );
                                                              }
                                                            }
                                                          )}
                                                          <Tbody>
                                                            {chng.from.map(
                                                              (
                                                                tabledata1,
                                                                tableIndex1
                                                              ) => {
                                                                if (
                                                                  tableIndex1 >
                                                                  0
                                                                ) {
                                                                  return (
                                                                    <Tr>
                                                                      {tabledata1.map(
                                                                        (
                                                                          d,
                                                                          tdIndex
                                                                        ) => {
                                                                          if (
                                                                            d.isDigit
                                                                          ) {
                                                                            return (
                                                                              <Td
                                                                                position="sticky"
                                                                                left={
                                                                                  0
                                                                                }
                                                                                bg="white"
                                                                                zIndex={
                                                                                  1
                                                                                }
                                                                              >
                                                                                <div
                                                                                  style={{
                                                                                    display:
                                                                                      "flex",
                                                                                    gridGap:
                                                                                      "2px",
                                                                                  }}
                                                                                >
                                                                                  {d.value.map(
                                                                                    (
                                                                                      val,
                                                                                      digitIndex
                                                                                    ) => {
                                                                                      return (
                                                                                        <div
                                                                                          key={
                                                                                            digitIndex
                                                                                          }
                                                                                        >
                                                                                          <div
                                                                                            className={
                                                                                              classes.ticketUnit
                                                                                            }
                                                                                          >
                                                                                            X
                                                                                          </div>
                                                                                          <input
                                                                                            type="checkbox"
                                                                                            checked={
                                                                                              val ===
                                                                                              1
                                                                                            }
                                                                                            style={{
                                                                                              width:
                                                                                                "10px",
                                                                                            }}
                                                                                            disabled
                                                                                          />
                                                                                        </div>
                                                                                      );
                                                                                    }
                                                                                  )}
                                                                                </div>
                                                                              </Td>
                                                                            );
                                                                          } else {
                                                                            if (
                                                                              !tdIndex
                                                                            ) {
                                                                              return (
                                                                                <Td
                                                                                  position="sticky"
                                                                                  left={
                                                                                    0
                                                                                  }
                                                                                  bg="white"
                                                                                  zIndex={
                                                                                    1
                                                                                  }
                                                                                >
                                                                                  <Box
                                                                                    className={
                                                                                      d.change
                                                                                        ? classes.label5
                                                                                        : classes.label8
                                                                                    }
                                                                                  >
                                                                                    <Text fontSize="10px">
                                                                                      {d.isBoolean ? (
                                                                                        d.value ? (
                                                                                          <DoneAllIcon
                                                                                            style={{
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          />
                                                                                        ) : (
                                                                                          <CloseIcon
                                                                                            style={{
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          />
                                                                                        )
                                                                                      ) : (
                                                                                        d.value
                                                                                      )}
                                                                                    </Text>
                                                                                  </Box>
                                                                                </Td>
                                                                              );
                                                                            } else {
                                                                              return (
                                                                                <Td>
                                                                                  <Box
                                                                                    className={
                                                                                      d.change
                                                                                        ? classes.label5
                                                                                        : classes.label8
                                                                                    }
                                                                                  >
                                                                                    <Text fontSize="10px">
                                                                                      {d.isBoolean ? (
                                                                                        d.value ? (
                                                                                          <DoneAllIcon
                                                                                            style={{
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          />
                                                                                        ) : (
                                                                                          <CloseIcon
                                                                                            style={{
                                                                                              fontSize: 12,
                                                                                            }}
                                                                                          />
                                                                                        )
                                                                                      ) : (
                                                                                        d.value
                                                                                      )}
                                                                                    </Text>
                                                                                  </Box>
                                                                                </Td>
                                                                              );
                                                                            }
                                                                          }
                                                                        }
                                                                      )}
                                                                    </Tr>
                                                                  );
                                                                }
                                                              }
                                                            )}
                                                          </Tbody>
                                                        </Table>
                                                      </TableContainer>
                                                    </ChkraBox>
                                                  </ChkraBox>
                                                </ChakraProvider>
                                              </div>
                                            ) : chng.listfieldsts ? (
                                              <>
                                                <ul className={classes.list11}>
                                                  {chng.from.map((li) => {
                                                    return (
                                                      <li>
                                                        <div
                                                          className={
                                                            classes.textBlock18
                                                          }
                                                        >
                                                          <p
                                                            className={
                                                              classes.labelWrapper7
                                                            }
                                                          >
                                                            {li.change ? (
                                                              <span
                                                                className={
                                                                  classes.label9
                                                                }
                                                              >
                                                                {li.value}
                                                              </span>
                                                            ) : (
                                                              <span
                                                                className={
                                                                  classes.label10
                                                                }
                                                              >
                                                                {" "}
                                                                {li.value}
                                                              </span>
                                                            )}
                                                          </p>
                                                        </div>
                                                      </li>
                                                    );
                                                  })}
                                                </ul>
                                              </>
                                            ) : (
                                              <p
                                                className={
                                                  classes.labelWrapper6
                                                }
                                              >
                                                <span
                                                  className={classes.label8}
                                                >
                                                  {chng.imgfieldsts ? (
                                                    <div
                                                      style={{
                                                        height: "55px",
                                                        width: "60px",
                                                        backgroundColor:
                                                          "#D9D9D9",
                                                        display: "grid",
                                                        placeItems: "center",
                                                        borderRadius: "5%",
                                                      }}
                                                    >
                                                      {" "}
                                                      <img
                                                        style={{
                                                          height: "50px",
                                                          width: "50px",
                                                        }}
                                                        src={chng.from}
                                                      />
                                                    </div>
                                                  ) : (
                                                    chng.from
                                                  )}
                                                </span>
                                              </p>
                                            )}
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                              </Box>
                            );
                          })}
                        </StepContent>
                      </Step>
                    );
                  });
                } else {
                  return (
                    <Step key={index} active={true}>
                      <StepLabel StepIconComponent={QontoStepIcon}>
                        <div className={classes.frame26082332}>
                          <Deleted />
                          <ByUserName
                            username={stepperdata[mode][0]["username"]}
                          />
                          <DateTime
                            datetime={stepperdata[mode][0]["logdatetime"]}
                          />
                        </div>
                      </StepLabel>
                      <StepContent>
                        <Box>
                          <div className={classes.group770672}>
                            <div className={classes.rectangle5926}></div>
                            <div
                              className={classes.deletedLotteryDeletedDearBaisa}
                            >
                              <div className={classes.textBlock}>Deleted</div>
                              <div className={classes.textBlock2}>
                                <p className={classes.labelWrapper}>
                                  <span className={classes.label}>
                                    -{rmodule} Deleted
                                  </span>
                                </p>
                              </div>
                              <ul className={classes.list}>
                                <li>
                                  <div className={classes.textBlock3}>
                                    <p className={classes.labelWrapper2}>
                                      <span className={classes.label2}>
                                        {stepperdata[mode][0]["mentionname"]}
                                        Deleted
                                      </span>
                                    </p>
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className={classes.rectangle5927}></div>
                          </div>
                        </Box>
                      </StepContent>
                    </Step>
                  );
                }
              })}
            </Stepper>
          </Box>
        </div>
      </Drawer>
      <div style={{ height: "100px" }}>
        <Grid container spacing={2}>
          <Grid className="log_grid_header" xs={12}>
            <h3>Log Management</h3>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid className="log_grid_item" xs={4}>
            <div>
              {/* <DropDown label={'module'} options={} /> */}
              <ChakraProvider>
                <FormLabel>Module</FormLabel>
                <Select
                  placeholder="Select option"
                  defaultValue={rmodule}
                  onChange={(e) => changeModule(e)}
                  backgroundColor="#FAFAFA"
                >
                  {[
                    "lottery",
                    "result",
                    "category",
                    "admin",
                    "offer",
                    "role",
                  ].map((opt) => {
                    return <option value={opt}>{dropDownLabel[opt]}</option>;
                  })}
                </Select>
              </ChakraProvider>
            </div>
          </Grid>
          <Grid className="log_grid_item" xs={4}>
            <div>
              <ChakraProvider>
                <FormLabel>Date Range</FormLabel>
                <DateRangePicker
                  format="yyyy-MM-dd"
                  placeholder="Select Date Range"
                  value={[new Date(date.fromdates), new Date(date.todates)]}
                  style={{ float: "left" }}
                  onChange={dateHandler}
                />
              </ChakraProvider>
            </div>
          </Grid>
          <Grid className="log_grid_item" xs={4}>
            <div className="">
              <ChakraProvider>
                <FormLabel style={{ visibility: "hidden" }}>f</FormLabel>
                <Button
                  isLoading={loading}
                  loadingText="Submitting.."
                  colorScheme="teal"
                  variant="outline"
                  height="34px"
                  lineHeight="1.2"
                  onClick={genReport}
                >
                  Submit
                </Button>
              </ChakraProvider>
            </div>
          </Grid>
        </Grid>
      </div>
    <div className="marginLogTop">
    <DataGridSetup
        heading=""
        rows={griddata}
        columns={columns[rmodule]}
        loading={loading}
        date={false}
        exportBtn={false}
      />
    </div>
    </div>
  );
};
export default Logs;
